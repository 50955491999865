import Swup from 'swup';
import SwupGtmPlugin from '@swup/gtm-plugin';
import SwupGaPlugin from '@swup/ga-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
//import SwupFadeTheme from '@swup/fade-theme';
import SwupOverlayTheme from '@swup/overlay-theme';
import InfiniteScroll from 'infinite-scroll';
import * as $ from "jquery";
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

const jQueryFoundationPreloader = () => {

  return new Promise( (resolve, reject) => {

    const jQuery3 = document.createElement('script')
    jQuery3.onload = resolve
    jQuery3.onerror = reject
    jQuery3.id = 'jquery-3'
    jQuery3.async = true
    jQuery3.src = 'https://code.jquery.com/jquery-3.4.1.min.js'
    document.body.appendChild(jQuery3);

  })
}

const foundationPreloader = () => {

  return new Promise( (resolve, reject) => {
    const foundationMin = document.createElement('script')
    foundationMin.onload = resolve
    foundationMin.onerror = reject
    foundationMin.id = 'foundationMin'
    foundationMin.async = true
    foundationMin.src = 'https://cdnjs.cloudflare.com/ajax/libs/foundation/6.5.3/js/foundation.min.js'
    document.body.appendChild(foundationMin);

  })
};

const swiperPreloader = () => {

  return new Promise((resolve, reject) => {
    const swiperMin = document.createElement('script')
    swiperMin.onload = resolve
    swiperMin.onerror = reject
    swiperMin.id = 'swiperMin'
    swiperMin.async = true
    swiperMin.src = 'https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.js'
    document.body.appendChild(swiperMin);

  })
};

const GoogleMapPreloder = () => {

  return new Promise( (resolve, reject) => {

    const GmapScript = document.createElement('script')

    GmapScript.onload = resolve
    GmapScript.onerror = reject
    GmapScript.id = 'google-maps-script'
    GmapScript.async = true
    GmapScript.defer = true
    GmapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBgQkIEP6xYuHKNPbsSbjDfqWvy3p7Lb2c'
    document.body.appendChild(GmapScript);

  })

};


// Start Scripts interactions

document.addEventListener("swup:animationInStart", event => {
  event.stopPropagation();
});

document.addEventListener("swup:contentReplaced", event => {
  G_FUNC.initGlobalFunctions();
});

document.addEventListener("swup:transitionStart", event => {

  var rand_color = G_FUNC.color_palette[Math.floor(Math.random() * G_FUNC.color_palette.length)];

  $('.swup-transition-overlay').css('background', rand_color);

});

const swup = new Swup({

  plugins: [

    new SwupGaPlugin(),
    new SwupGtmPlugin(),
    new SwupBodyClassPlugin(),
    new SwupOverlayTheme()

  ],

  cache: false,

  skipPopStateHandling: function(event) {

    if (event.state && event.state.source == "swup") {
      return false;
    }

    if( !event.state )
    {
      return false;
    }
    return true;
  }
});

// On passe au site après l'instanciation de swup

$(document).ready(function(){

  // cookies carte de voeux
  $('.popincookies__close').on('click', function() {
    $('.popincookies').addClass('closed');
    setCookie( 'popupCookie', 'closed', 1 );
  });

  $('.popincookies__share-button').on('click', function() {
    $('.popincookies__content, .popincookies__smiley, .popincookies__share-button, .popincookies__close').fadeOut('fast');
    $('.popincookies__thanks').addClass('open');
    setCookie( 'popupCookie', 'submited', 30 );
    setTimeout(function(){
      $('.popincookies').addClass('closed');
      $('.popincookies__content, .popincookies__smiley, .popincookies__share-button, .popincookies__close').fadeIn('fast');
      $('.popincookies__thanks').removeClass('open');
    }, 6500);
  });

  //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
  if( getCookie('popupCookie') != 'submited'){
    if(getCookie('popupCookie') != 'closed' ){
      $('.popincookies').removeClass('closed');
    }
  }

  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  $('.switcher').on('click', function (event) {
    if ($(this).hasClass('switcher--left')) {
      $(this).removeClass('switcher--left').addClass('switcher--right');
    } else {
      $(this).removeClass('switcher--right').addClass('switcher--left');
    }
  });

  $('.is-no-swup').find("a").attr("data-no-swup","");

  // add smiley rotate

  //$('.swup-transition-overlay')

  G_FUNC.initGlobalFunctions();

  jQueryFoundationPreloader().then( () => {

    foundationPreloader().then( () => {

      jQuery(document).ready(() => {

        Foundation.addToJquery($);

        jQuery(document).foundation();
      });

    });

  });

  G_FUNC.initMenuFunctions();

});

var G_FUNC = {

  initGlobalFunctions: function() {

    this.initMenuScrollFunction();

    this.scrollToTop();

    this.initHomeFunctions();

    this.initAgenceFunctions();

    this.initEngagementsFunctions();

    this.initJobsFunctions();

    this.initCreationsFunctions();

    this.initActusFunctions();

    this.initSecretPageFunction();

    this.initSingleFunctions();

    $(window).scroll();

  },

  isScrolledIntoView: function(ref,elem)

  {

    var docViewTop = $(ref).offset().top;

    var docViewBottom = docViewTop + $(ref).height();

    var elemTop = $(elem).offset().top;

    var elemBottom = elemTop + $(elem).outerHeight();

    return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom >= docViewBottom) && (elemTop <= docViewTop));

  },

  scrollToTop: function() {

    $('html,body').animate({scrollTop: 0}, 'slow');

  },

  initMenuScrollFunction: function() {

    var $this = this;

    var whiteElement = ['.barbes-logo','label[for="burger"]', '.current-page-container'];

    function isTrue(element, index, array) {

      return element;

    }

    $(window).on('scroll', function(){

      var trueFalseRef = [];

      $( 'div[data-is-white="true"]' ).each(function( index ) {

        var $thatDiv = $( this );

        $.each( whiteElement, function( key, value ) {

          if( $this.isScrolledIntoView( value , $thatDiv) )

          {

            trueFalseRef.push(value);

          }

        });

      });

      $.each( whiteElement, function( key, value ) {

        if( trueFalseRef.includes( value ) )
        {
          if( !($(value).hasClass('is-white')) )
          {
            $(value).addClass('is-white');
          }

        } else

        {

          if( ($(value).hasClass('is-white')) )

          {

            $(value).removeClass('is-white');

          }

        }

      });

    });

    $('#show-newsletter').on('click', function(e) {

      e.preventDefault();

      $(this).fadeOut("slow", function() {

        $('#hide-newsletter').fadeIn();

      });

    })

  },

  initAgenceFunctions: function() {
    if ($('#content').hasClass('is--agence')) {
      swiperPreloader().then(() => {
        const swiper = new Swiper('.bubbles', {
          direction: 'vertical',
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            // reverseDirection: true,
          },
          speed: 400,
          spaceBetween: 20,
          slidesPerView: 'auto',
          // centeredSlides: true,
        });
      });

      const isTags = document.querySelector('.is-tags');
      isTags.style.width = document.querySelector('span[data-show]').offsetWidth + 'px';

      setInterval(function () {
        
        const show = document.querySelector('span[data-show]');

        if (!show) {
          return false;
        }

        let next = show.nextElementSibling || document.querySelector('.is-tag:first-child');
        const sizeShow = next.offsetWidth;
        isTags.style.width = sizeShow + 'px';

        const up = document.querySelector('span[data-up]')
        if (up) {
          up.removeAttribute('data-up')
        }
        show.removeAttribute('data-show');
        show.setAttribute('data-up', '');
        next.setAttribute('data-show', '');
        

      }, 2000);

      (function () {
        const lights = document.getElementsByClassName('photo-gallery');
        let previousLight = null;

        function repeatOften() {
          if (previousLight){
            previousLight.classList.toggle('is-active');
          }

          const random = Math.floor(Math.random() * (lights.length - 1)) + 0;
          const randomLight = lights[random];
          randomLight.classList.toggle('is-active');
          previousLight = randomLight;
          setTimeout(repeatOften, 5000);
        }

        setTimeout(repeatOften, 5000);
      })();
    }
  },

  initEngagementsFunctions: function(){
    if ( $('#content').hasClass('is--engagements') ) {
      $('.tabs-title-link').on('click', function(event) {
        if ($(this).parent().hasClass('is-active')) {
          $(this).parent().removeClass('is-active');
          $($(this).attr('href')).removeClass('is-active');
        } else {
          $('.tabs-title.is-active').removeClass('is-active');
          $('.tabs-text.is-active').removeClass('is-active');
          $(this).parent().addClass('is-active');
          $($(this).attr('href')).addClass('is-active');
        } 
      });
    }
  },

  initHomeFunctions: function() {

    if( $('#content').hasClass('is--home' ) )

    {
      var $this = this;

      var previousValue;

      $('#go-to-intro-container').on('click',function() {

        $('html,body').animate({scrollTop: $("#intro-container").offset().top}, 'slow');

      });

      $(window).on('scroll', function(){

    if( window.scrollY > 1000 )

    {

      $('.is-video-container').css('opacity','0');

    } else

    {

      $('.is-video-container').css('opacity','1');

    }

    // Animation droite Home

    var trueFalseRef = [];

    $( 'div[data-label]' ).each(function( index ) {

      var $thatDiv = $( this );

      if( $this.isScrolledIntoView( '#home-smiley-container' , $thatDiv ) )

      {

        trueFalseRef.push($thatDiv.data('label'));

      }

    });

    if( trueFalseRef.length > 0 &&  trueFalseRef != 'hide' )

    {

      if( !($('#home-smiley-container').hasClass('appear')) )

      {

        $('#home-smiley-container').addClass('appear');

      }

      if( previousValue != trueFalseRef )

      {

        $('#home-smiley-container').addClass('is-transition');

        setTimeout(() => {

          $('#name-section').html(trueFalseRef);

          $('#home-smiley-container').removeClass('is-transition');

        }, 300);

      }

      previousValue = trueFalseRef[0];

    } else if( trueFalseRef == 'hide' )

    {

      if( ($('#home-smiley-container').hasClass('appear')) )

      {

        $('#home-smiley-container').removeClass('appear');

      }

    }



  });

}


// Hover video



$('body').on('mouseenter','.is-creation-container',function() {

  var video_src = $(this).data('video');

  if(video_src.length > 0)

  {

    $(this).find('.video-hover').append('<video data-object-fit="cover" muted="muted" loop="loop" autoplay="autoplay" style=""><source src="'+ video_src +'" type="video/mp4"></video>');

  }

});


$('body').on('mouseleave','.is-creation-container', function() {

  $(this).find('.video-hover').empty();

})


},


initJobsFunctions: function() {

  if( $('#content').hasClass('is--jobs' ) )
  {
    // On réinstancie les accordions

    jQueryFoundationPreloader().then( () => {

      foundationPreloader().then( () => {

        jQuery(document).ready(() => {

          Foundation.addToJquery($);

          jQuery(document).foundation();

          // Si il y a une ancre dans l'url

          if(window.location.hash) {

            setTimeout(() => {

              var hash = String(window.location.hash);

              jQuery('#acc').foundation('toggle', $(hash));

              jQuery('html,body').animate({scrollTop: ($(hash).offset().top - 200)}, 'slow');



            }, 250);

          }

        });

      });

    });

    // A l'ouverture d'un job

    $('.accordion-title').on('click', function() {

      setTimeout(() => {

        $('html,body').animate({scrollTop: $(this).offset().top}, 'slow');

      }, 250);

    });

  }

},
initMenuFunctions: function() {

  $('#burger').on('click', function() {

    if($(this).prop('checked'))

    {

      $('.menu--holder').addClass('open');

    } else

    {

      $('.menu--holder').removeClass('open');

    }



  });

  // On ferme le burger quand on clique sur un lien

  $('.menu--container').find('a').on('click', function() {

    setTimeout(() => {

      $('#burger').click();

    }, 500);

  });

  $('#logo-svg').on('click', function() {

    if($('#burger').prop('checked'))

    {

      setTimeout(() => {

        $('#burger').click();

      }, 500);

    }

  });

},
initCreationsFunctions: function() {

  if(G_FUNC.creationsInfScroll)

  {
    G_FUNC.creationsInfScroll.destroy()
  }

  if( $('#content').hasClass('is--listing-creation' ) )
  {

    initInfiniteScroll();

    var current_url = $('#url_value').val();
    var lastChar = current_url.substr(current_url.length - 1);

    function initInfiniteScroll() {
      G_FUNC.creationsInfScroll = new InfiniteScroll( '.container-infinite', {

        // defaults listed
        path: function() {

          var pageNumber = this.loadCount + 2;
          if(lastChar == '/')
          {
            return current_url + '?paginated=' + pageNumber;
          } else
          {
            return current_url + '&paginated=' + pageNumber;
          }
        },

        append: '.is-creation-container'
        // REQUIRED for appending content
        // Appends selected elements from loaded page to the container

      })
    }

    function killInfiniteScroll() {
      G_FUNC.creationsInfScroll.destroy()
    }
    // Hover video

    $('body').on('mouseenter','.is-creation-container',function() {

      var video_src = $(this).data('video');
      if(video_src.length > 0)
      {
        $(this).find('.video-hover').append('<video data-object-fit="cover" muted="muted" loop="loop" autoplay="autoplay" style=""><source src="'+ video_src +'" type="video/mp4"></video>');
      }
    });

    $('body').on('mouseleave','.is-creation-container', function() {
      $(this).find('.video-hover').empty();
    })

    jQuery('.is-category-container').on('click', function(e) {

      const url = new URL(location.protocol + '//' + location.host + location.pathname)

      /* jQuery('#postContent').hide() */

      if(jQuery(this).hasClass('active')) {

        initInfiniteScroll()
        jQuery('.is-category-container').removeClass('active')

      } else {

        jQuery('.is-category-container').removeClass('active')

        killInfiniteScroll()

        jQuery(this).addClass('active')
        const term_id = jQuery(this).data('key')
        url.searchParams.append('filteredby', term_id)
        url.searchParams.append('paginated', 'nope')

      }
      $.ajax({
        url: url
      }).then(
        response => {
          // Search and replace
          const $html = $(response)
          const $posts = $('#postContent', $html)
          jQuery('#postContent').empty().append($posts.html())
          // Show container
          /* jQuery('#postContent').show() */
        },
        response => {
          console.error(response)
        }
      )
    })

    jQuery('#filterTarget').on('click', function() {
      jQuery('.list-filters').toggleClass('active')
      jQuery(this).find('.arrow').toggleClass('rotate')
    })


  }

},
initActusFunctions: function() {

  if(G_FUNC.actusInfScroll)

  {

    G_FUNC.actusInfScroll.destroy()

  }

  if( $('#content').hasClass('is--listing-actualites' ) )

  {

    G_FUNC.actusInfScroll = new InfiniteScroll( '.is-actus-container', {

      path: function() {

        var pageNumber = this.loadCount + 2;

        return '?paginated=' + pageNumber;

      },

      append: '.is-inf-creation'

    })

  }

},
initSingleFunctions: function() {

  if( $('#content').hasClass('is--single' ) )

  {

    if($('#player').length > 0) {

      const player = new Plyr('#player',

      {

        hideControls: false,
        youtube: {
          noCookie: false,
          cc_load_policy: 0,
          controls: 2,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          controls:0
        }

      });

      player.poster = $('#label-player').data('preview');

      player.toggleControls(false)

      player.on('play', event => {

        if(!$('#player').hasClass('hideControl'))

        {

          player.toggleControls(true);

        }

        $('.plyr__poster').css('opacity','0');

      });

      player.on('pause', event => {

        $('.plyr__poster').css('opacity','1');

      });

    }

  }

},
initSecretPageFunction: function() {

  //!B

  var k = [223, 66];

  var n = 0;

  var u = [8, 66];

  var p = 0;

  var hiddenPlayer;

  var hiddenPoster = $('#hidden-video').data('preview');

  $(document).keydown(function (e) {

    if (e.keyCode === k[n++]) {

      if (n === k.length) {

        $("#hidden-page").addClass('appear');

        initVideoSecret();

        n = 0;

        return false;

      }

    }

    else {

      n = 0;

    }

    if (e.keyCode === u[p++]) {

      if (p === u.length) {

        $("#hidden-page").addClass('appear');

        initVideoSecret();

        p = 0;

        return false;

      }

    }

    else {

      p = 0;

    }

    function initVideoSecret() {

      if(hiddenPlayer == undefined) {

        hiddenPlayer = new Plyr('#hidden-video',

        {

          hideControls: false,

          youtube: { noCookie: false, cc_load_policy: 0, controls: 2, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, controls:0 }

        });

        hiddenPlayer.poster = hiddenPoster;

        hiddenPlayer.toggleControls(false)

        hiddenPlayer.on('play', event => {

          if(!$('#hidden-video').hasClass('hideControl'))

          {

            hiddenPlayer.toggleControls(true);

          }

          $('.plyr__poster').css('opacity','0');

        });

        hiddenPlayer.on('pause', event => {

          $('.plyr__poster').css('opacity','1');

        });

      }

      hiddenPlayer.play();

    }

  });

  $('label[for="burger--hidden"]').on('click', function() {

    if( $('#hidden-page').hasClass('appear') )

    {

      $('#hidden-page').removeClass('appear');

      hiddenPlayer.pause();

    }

  })
},
};
G_FUNC.creationsInfScroll;



G_FUNC.actusInfScroll;



G_FUNC.color_palette = ['#8033FF', '#C99811', '#55906D'];
